<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Talent Area</v-card-title>
          <v-card-subtitle>As the data is structured in many layers only basic information is currently displayed. Working on a full talent + profile view.</v-card-subtitle>
          <v-card-text>
            <table-crud-component table-name="Talent" endpoint="/api/talent"></table-crud-component>
<!--            <v-data-table-->
<!--                :headers="talentHeaders"-->
<!--                :items="talent"-->
<!--                :loading="loading"-->
<!--                :search="talentSearch"-->
<!--            ></v-data-table>-->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import TableCrudComponent from "@/components/tables/TableCrudComponent";
export default {
  name: "Talent",
  components: {TableCrudComponent},
  mounted() {
    this.getTalent()
  },

  data() {
    return {
      talentHeaders: [
        { text: "id", value: "id" },
        { text: "name", value: "name" },
        { text: "email", value: "email" },
        { text: "address", value: "address" },
        { text: "zip", value: "zip" },
        { text: "city", value: "city" },
        { text: "country", value: "country" },
        { text: "website", value: "website" },
        { text: "rate", value: "rate" },
        { text: "external rate", value: "external_rate" },
        { text: "display mode", value: "display_mode" },
        { text: "date created", value: "created_at" },
        { text: "last updated", value: "updated_at" },
      ],
      talent: [],
      talentSearch: "",
      loading: true,
    }
  },

  methods: {
    getTalent() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/talent`, this.$store.state.headers)
          .then(function (response) {
            self.talent = response.data
            self.loading = false
          });
    },
  }
}
</script>

<style scoped>

</style>
